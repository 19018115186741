// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'name'
  }, {
    prop: 'evses'
  }, {
    prop: 'tariffs'
  }]
}
